

import React from "react" 
 
const Title = ({ title}) => {
    
    return (
        <>
        <main className=" mx-auto px-4 py-4 sm:py-8 bg-black relative overflow-hidden">
        <div className=" ">
            <h1 className="text-2xl  font-bold text-red-600 sm:text-4xl tracking-wider italic uppercase max-w-7xl mx-auto lg:px-8">
            <span className="block xl:inline">{title} </span>
             
            </h1>
           
            <div className="  sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full text-gray-800" aria-hidden="true">
                <div className="relative h-full max-w-7xl mx-auto">
                 
                <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 md:-translate-y-1/2 lg:-translate-x-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
                    <defs>
                    <pattern id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4"  fill="currentColor" />
                    </pattern>
                    </defs>
                    <rect width="404" height="784" fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
                </svg>
                </div>
            </div>
        </div>
        </main>
        </>
    )

}
 
  
  export default Title
  