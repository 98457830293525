import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import Components from "../components/components.js";
import { useStaticQuery, graphql } from "gatsby"  
import Title from "../components/title";

export default function Reference() {
    const data = useStaticQuery(graphql`
    query AllQuotes {
        scomos {
          articleFindAll(category:"reference",orderBy:"date DESC") {
            id
            name
            prologue
          }
        }
      }
  `) 

    return (
    <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Reference | Spark Performance</title> 
        </Helmet>
        <Layout>
        <Title title={'Reference'}  />
        {data.scomos.articleFindAll.map((child,i) => { 
            const  component = {
                name: 'Testimonials',
                data: child,
                param: 1
            }
            return Components(component) 
        })}
        
        

        </Layout>
    </>
    
    )
}
  